<template>
  <div class="trade-index">
    <van-nav-bar :title="title" left-arrow @click-left="onBack">
      <template #left>
        <van-icon name="arrow-left" color="#3d3d56" />
      </template>
      <template #right>
        <img :src="favourite[active]" @click="onSwitchSrc"/>
      </template>
    </van-nav-bar>
    <div class="trade-spotkline-index">
      <SpotKlineHeader ref="header" />
      <SpotKlineChart ref="chart" :deepsData="deeps" />
      <SpotKlineAction ref="action" :deepsData="deeps" />
      <div class="trade-spotkline-index-button">
        <div class="trade-spotkline-index-button-wrapper">
          <van-button @click="onBuy" :style="{'backgroundColor': upColor, 'color': color}">{{ $t("trade.buy") }}</van-button>
          <van-button @click="onSell" :style="{'backgroundColor': downColor, 'color': color}">{{ $t("trade.sell") }}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SpotKlineHeader from '@/components/trade/spotKlineHeader'
import SpotKlineAction from '@/components/trade/spotKlineAction'
import SpotKlineChart from '@/components/trade/spotKlineFeatures/index.vue'

import { TradeLike } from '@/api/auth.js'
import { Deep } from '@/api/market.js'
import { mapGetters, mapActions } from 'vuex'
import { interval } from '@/utils/common.js'

export default {
  components: {
    SpotKlineHeader,
    SpotKlineAction,
    SpotKlineChart
  },
  data () {
    const unActive = require('@/assets/images/market/start.svg')
    const active = require('@/assets/images/market/start-active.svg')
    return {
      upColor: 'var(--up-color)',
      downColor: 'var(--down-color)',
      color: 'var(--bg-1)',
      favourite: {
        1: active,
        2: unActive
      },
      chart: false,
      action: false,
      header: false,
      isInit: true,
      pollinger: null,
      deeps: {
        bids: [],
        asks: []
      }
    }
  },
  computed: {
    title () {
      return this.$route.params.symbol
    },
    active: {
      get () {
        return this.favoritesMarket.find(item => item === this.title) ? 1 : 2
      },
      set (val) {
        // 添加自选后更新 favoritesMarket 缓存
        if (val === 1) {
          this.updateFavoritesMarket([...this.favoritesMarket, this.title])
        } else if (val === 2) {
          const nf = this.favoritesMarket.filter(item => item !== this.title)
          this.updateFavoritesMarket(nf)
        }
      }
    },
    ...mapGetters(['favoritesMarket', 'token'])
  },
  mounted () {
    const { init } = this.$refs.chart
    init().then(() => {
      this.pollinger = interval(this.onPolling)
      this.pollinger.start()
      this.isInit = false
    })
  },
  beforeDestroy () {
    this.pollinger.stop()
  },
  methods: {
    onBack () {
      this.$router.push('/market')
    },
    onBuy () {
      this.$router.push(`/feature/${this.title}`)
    },
    onSell () {
      this.$router.push(`/feature/${this.title}`)
    },
    onSwitchSrc () {
      if (!this.token) {
        this.$toast(this.$t('nav.unLogin'))
      } else {
      // TradeLike()
        const symbols = [this.title]
        // 操作类型：1.加入 2.删除
        const type = this.active === 1 ? 2 : 1
        const msg = this.active === 1 ? this.$t('trade.unFavourite') : this.$t('trade.favourite')
        TradeLike({ symbols, type }).then(res => {
          this.$toast.clear()
          if (res) {
            this.$toast.success(msg)
            this.active = type
          }
        })
      }
    },
    getDeepData () {
      return Deep({
        limit: 30,
        symbol: this.title
      }).then(res => {
        if (res) {
          this.deeps.bids.length = 0
          this.deeps.asks.length = 0
          res.asks.forEach(item => {
            this.deeps.asks.push(item)
          })
          res.bids.forEach(item => {
            this.deeps.bids.push(item)
          })
        }
        return res
      })
    },
    async onPolling () {
      const { onPollingHead } = this.$refs.header
      const { onPollingAction } = this.$refs.action
      const { onPollingKline } = this.$refs.chart
      return Promise.all([onPollingKline(), onPollingHead(), onPollingAction(this.isInit)])
      // return Promise.all([onPollingKline()])
    },
    ...mapActions(['updateFavoritesMarket'])
  }
}
</script>
<style lang="less">
.trade-index {
  color: var(--main-text-1);
  font-size: 4.8vw;
  background-color: var(--background-color-2);
  .trade-spotkline-index {
    padding-bottom: 128px;
  }
  .trade-spotkline-index-button {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 99;
    background: var(--bg-1);
    .trade-spotkline-index-button-wrapper {
      display: flex;
      justify-content: space-around;
      padding: 20px;
      button {
        width: 45%;
        border-radius: 5px;
      }
    }
  }
}
</style>
