export function interval (cb, interval = 2000) {
  let timer
  let isStop = false
  const stop = () => {
    isStop = true
    clearTimeout(timer)
  }
  const start = async () => {
    isStop = false
    await loop()
  }
  const loop = async () => {
    try {
      await cb(stop)
    } catch (err) {
      console.error('轮询出错：', err)
      throw new Error('loop error：', err)
    }
    if (isStop) return
    return (timer = setTimeout(loop, interval))
  }
  return {
    start,
    stop
  }
}
