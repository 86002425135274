<template>
  <div class="spotkline-wrapper">
    <div class="spotkline-wrapper-newsPrice" @click="show = true">
      {{ $t(`market.${actionValue}`) }}
      <img src="@/assets/images/market/down-arrows.svg" />
    </div>
    <div class="ub ub-btw">
      <div class="ub-f1 spotkline-wrapper-left">
        <div class="spotkline-wrapper-currentWrapper">
          <span
            class="spotkline-wrapper-bigFont spotkline-wrapper-sell"
            :style="{ color: `var(${rate})` }"
            >{{
              ticker24Data.last_price
            }}</span
          >
        </div>
        <div class="spotkline-wrapper-fiatPriceWrapper">
          <div class="pricetext-index-text">
            <span class="pricetext-index-text-price"
              >≈ ${{
                ticker24Data.high_price
              }}</span
            ><span :style="{ color: `var(${rate})` }"
              >{{
                formatNumberWithCommasAndDecimal(
                  ticker24Data.price_change_percent,
                  2
                )
              }}%</span
            >
          </div>
          <div class="components-pricepercent-index-neg">
            <span>{{ $t(`market.${actionValue}`) }}</span>
            <span class="components-pricepercent-index-neg-price">{{
              tagPrices[actionValue]
            }}</span>
          </div>
        </div>
      </div>
      <div class="spotkline-wrapper-right">
        <div class="spotkline-wrapper-infoWrapper ub top ub-btw">
          <div class="spotkline-wrapper-infoItem">
            <p class="color-middle">24h {{ $t("market.highPrice") }}</p>
            <p class="spotkline-wrapper-infoValue">
              {{ formatNumberWithCommasAndDecimal(ticker24Data.high_price) }}
            </p>
          </div>
          <div class="spotkline-wrapper-infoItem">
            <p class="color-middle info-right">
              24h {{ $t("market.currency") }}({{ coin }})
            </p>
            <p class="spotkline-wrapper-infoValue info-right">
              {{ ticker24Data.volume || "-" }}
            </p>
          </div>
        </div>
        <div class="spotkline-wrapper-infoWrapper ub ub-btw">
          <div class="spotkline-wrapper-infoItem">
            <div class="color-middle">24h {{ $t("market.lowPrice") }}</div>
            <div class="spotkline-wrapper-infoValue">
              {{ formatNumberWithCommasAndDecimal(ticker24Data.low_price) }}
            </div>
          </div>
          <div class="spotkline-wrapper-infoItem">
            <div class="color-middle info-right">
              24h {{ $t("market.currencyPrice") }}({{ coin }})
            </div>
            <div class="spotkline-wrapper-infoValue info-right">
              {{ ticker24Data.quote_volume || "-" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet
      v-model="show"
      @select="onSelect"
      :cancel-text="$t('nav.cancel')"
      @cancel="onCancel"
      close-on-click-action
    >
      <button
        type="button"
        class="van-action-sheet__item"
        v-for="(action, index) in actions"
        :key="index"
        @click="() => onSelect(action)"
      >
        <span
          class="van-action-sheet__name"
          :class="{
            active: action.name === actionValue,
          }"
          >{{ $t(`market.${action.name}`) }}</span
        >
      </button>
    </van-action-sheet>
  </div>
</template>
<script>
import { Ticker, MarkPriceAPi } from '@/api/market.js'
export default {
  data () {
    return {
      show: false,
      showPopover: false,
      actionValue: 'lastPrice',
      coin: 'USDT',
      ticker24Data: {
        high_price: '--',
        low_price: '--',
        last_price: '',
        volume: '--',
        quote_volume: '--',
        price_change_percent: '--'
      },
      actions: [
        { name: 'lastPrice' },
        { name: 'markPrice' },
        { name: 'indexPrice' }
      ],
      tagPrices: {
        lastPrice: '--',
        markPrice: '--',
        indexPrice: '--'
      }
    }
  },
  computed: {
    rate () {
      return this.ticker24Data.price_change_percent.indexOf('-') !== -1
        ? '--down-color'
        : '--up-color'
    },
    symbol () {
      return this.$route.params.symbol.replace('USDT', '')
    },
    symbolAll () {
      return this.$route.params.symbol.replace('.', '')
    }
  },
  methods: {
    getContainer () {
      return document.querySelector('.spotkline-wrapper-funding-text')
    },
    async onPollingHead () {
      return Promise.all([this.getTicker(), this.getMarkPrice()]).then((res) => {
        return res
      })
    },
    getTicker () {
      return Ticker(this.symbolAll).then((data) => {
        for (const k in data) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.ticker24Data.hasOwnProperty(k)) {
            this.$set(this.ticker24Data, k, data[k])
          }
          this.tagPrices.lastPrice = data.last_price
        }
        this.$toast.clear()
        return data
      })
    },
    getMarkPrice () {
      return MarkPriceAPi({ symbol: this.symbolAll }).then(res => {
        this.$toast.clear()
        if (res) {
          this.tagPrices.markPrice = res.price
          this.tagPrices.indexPrice = res.index_price
        }
        return res
      })
    },
    formatNumberWithCommasAndDecimal (num, sub = 2) {
      // 将输入转换为数字类型，以便处理
      num = parseFloat(num)

      // 检查是否为有效数字
      if (isNaN(num)) {
        return 'Invalid number'
      }

      // 分离整数部分和小数部分
      const parts = num.toString().split('.')
      let integerPart = parts[0]
      let decimalPart = parts[1] || ''

      // 将整数部分格式化为千分位
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      // 如果小数部分长度大于等于2，则截取前两位小数
      if (decimalPart.length >= sub) {
        decimalPart = decimalPart.substring(0, sub)
      }

      // 拼接整数部分和小数部分
      const formattedNum =
        decimalPart !== '' ? integerPart + '.' + decimalPart : integerPart

      return formattedNum
    },
    numberToChinese (num) {
      if (num < 10000) {
        return this.formatNumberWithCommasAndDecimals(num)
      } else if (num < 100000000) {
        const decimal = Math.floor((num % 10000) / 1000)
        if (decimal === 0) {
          return (
            this.formatNumberWithCommasAndDecimals(Math.floor(num / 10000)) +
            `${this.$t('market.M')}`
          )
        } else {
          return (
            this.formatNumberWithCommasAndDecimals(Math.floor(num / 10000)) +
            '.' +
            decimal +
            `${this.$t('market.M')}`
          )
        }
      }
      // else {
      //   const decimal = Math.floor((num % 100000000) / 10000000)
      //   if (decimal === 0) {
      //     return this.formatNumberWithCommasAndDecimals(Math.floor(num / 100000000)) + '亿'
      //   } else {
      //     return this.formatNumberWithCommasAndDecimals(Math.floor(num / 100000000)) + '.' + decimal + '亿'
      //   }
      // }
    },
    formatNumberWithCommasAndDecimals (num) {
      return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onSelect (val) {
      this.actionValue = val.name
      this.show = false
    },
    onCancel () {}
  }
}
</script>
<style lang="less">
.spotkline-wrapper {
  padding: 2.66667vw 4vw;
  background-color: var(--background-color-4);
  font-family: Roboto;
  font-style: normal;
  text-transform: capitalize;
  &-newsPrice {
    display: inline-block;
    color: var(--main-text-3);
    font-size: 20px;
    font-weight: 400;
    img {
      width: 10px;
      height: 10px;
      vertical-align: middle;
    }
  }
  &-right, &-left {
    height: 128px;
  }
  &-currentWrapper {
    line-height: 72px;
  }

  .top {
    height: 72px;
  }
  .color-middle {
    line-height: 28px;
  }
  &-infoWrapper {
    color: var(--main-text-3);
    text-align: right;
    font-size: 20px;
    font-weight: 400;
    .info-right {
      padding-left: 32px;
    }
    .spotkline-wrapper-infoValue {
      color: var(--main-text-1);
      text-align: right;
      font-weight: 500;
      line-height: 28px; /* 140% */
    }
  }
  &-fiatPriceWrapper {
    color: var(--main-text-1);
    font-size: 20px;
    font-weight: 500;
    .pricetext-index-text {
      &-price {
        margin-right: 8px;
      }
    }
  }
  .components-pricepercent-index-neg {
    color: var(--main-text-3);
    font-size: 20px;
    font-weight: 400;
    line-height: 28px; /* 140% */
    &-price {
      color: var(--main-text-2);
      margin-left: 8px;
    }
  }
  .active {
    color: var(--primary-color);
  }
  &-sell {
    font-size: 48px;
    font-weight: 500;
  }
}
</style>
