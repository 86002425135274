<template>
  <div class="spotkline-wrapper-spot-features">
    <div class="charts-wrapper">
      <div class="ub ub-btw spotkline-wrapper-spot-tabs">
        <div class="tabs">
          <div class="tabs-item" v-for="(item, index) in tabs" :key="index">
            <template v-if="!item.icon">
              <span
                :class="{ 'tabs-item-text-active': current === item.value }"
                class="tabs-item-text"
                @click="() => onSwitchTabs(item)"
                >{{ $t(`trade.${item.label}`) }}</span
              >
            </template>
            <template v-else>
              <van-popover
                v-model="showTime"
                trigger="click"
                get-container="getContainer"
                placement="bottom-start"
                class="popover"
              >
                <div class="time-wrapper">
                  <div
                    class="time-item"
                    v-for="interval in intervals"
                    :key="interval.value"
                    v-text="$t(`trade.${interval.label}`)"
                    :class="{
                      'time-item-active': interval.value === activeTimeValue,
                    }"
                    @click="() => onPickInterval(interval.value, item.value)"
                  ></div>
                </div>
                <template #reference>
                  <div
                    class="time my-container tabs-item-text"
                    :class="{ 'tabs-item-text-active': current === item.value }"
                  >
                    {{ activeTimeLabel }}
                    <img
                      src="@/assets/images/market/down-arrows.svg"
                      v-if="item.icon"
                    />
                  </div>
                </template>
              </van-popover>
            </template>
          </div>
        </div>
        <img src="@/assets/images/market/fullscreen.svg" class="fullscreen" v-show="false"/>
      </div>
      <div id="indicator-k-line" class="k-line-chart" />
    </div>
    <SpotKlineIndex @indexSpot="onHideIndex"/>
  </div>
</template>

<script>
import SpotKlineIndex from '@/components/trade/spotKlineIndex'
import kline from './kline2'

export default {
  ...kline,
  components: { SpotKlineIndex }
}
</script>
<style lang="less">
@import "~@/assets/styles/mixins.less";
.spotkline-wrapper-spot-features {
  background-color: var(--background-color-4);
  .spotkline-wrapper-spot-tabs {
    border-bottom: 1px solid var(--divider-color);
  }
}
.charts-wrapper {
  padding-bottom: 10px;
  .time-wrapper {
    box-sizing: border-box;
    margin: 4px 0px 0px;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 24px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px;
    .time-item {
      box-sizing: border-box;
      margin: 0px;
      min-width: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 4px;
      padding-bottom: 4px;
      text-align: center;
      cursor: pointer;
      width: 20%;
      font-size: 28px;
    }
    .time-item-active {
      color: var(--primary-color);
    }
  }
  .k-line-chart {
    width: 100vw;
    height: 768px;
  }
  .popover {
    .van-popover {
      left: 0 !important;
    }
  }

  .tabs {
    padding: 0 4vw;
    box-sizing: border-box;
    min-width: 0px;
    display: flex;
    align-items: center;
    line-height: 48px;
    &-item {
      margin-right: 32px;
      display: flex;
      align-items: center;
      img {
        margin-left: 8px;
        width: 16px;
        height: 16px;
      }
    }
    &-item-text {
      color: var(--main-text-3);
      text-align: center;
      font-size: 24px;
      font-weight: 400;
    }
    &-item-text-active {
      color: var(--primary-color) !important;
      font-weight: 500;
    }
    &-item-bar-active {
      width: 16px;
      background-color: var(--primary-color);
    }
  }
  .time {
    // width: 100px;
    // text-align: center;
    box-sizing: border-box;
    margin: 0px 0px 0px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-text-3);
    cursor: pointer;
    font-size: 24px;
    line-height: 16px;
    padding: 4px;
    border-radius: 2px;
    min-width: 32px;
  }
  .van-tab {
    flex: none;
    margin-right: 9vw;
    padding-bottom: 1.06667vw;
    font-size: 3.7vw;
    color: var(--main-text-1);
    &--active {
      font-weight: unset;
      .border-1px(var(--main-text-1));
    }
  }
  .van-tabs {
    &__wrap {
      height: inherit;
    }
    &__line {
      background-color: transparent;
    }
    &__nav--line {
      padding: 0;
    }
    &__content {
      margin: 1.06667vw 0 1.6vw;
    }
  }
  .fullscreen {
    width: 32px;
    height: 32px;
  }
}
</style>
