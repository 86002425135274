<template>
  <div class="trade-spotkline-actions">
    <van-tabs v-model="tabActive">
      <van-tab
        v-for="tab in tabs"
        :key="tab.value"
        :title="$t(`trade.${tab.label}`)"
      >
        <div
          class="trade-spotkline-side-wrapper"
          v-if="tab.label === 'orderBook'"
        >
          <div class="trade-spotkline-side">
            <div class="trade-spotkline-side-marketTitle tsr-marketTitle">
              {{ $t("market.bid") }}
            </div>
            <div class="trade-spotkline-side-tableRow" v-for="(bid, bIdx) in deepsData.bids" :key="bIdx">
              <div>{{ bid.price }}</div>
              <div class="trade-spotkline-side-buy">{{ bid.quantity }}</div>
            </div>
          </div>
          <div class="trade-spotkline-side">
            <div class="trade-spotkline-side-marketTitle tsr-marketTitle ub ub-btw">
              <span>{{ $t("market.ask") }}</span>
              <div
                v-show="false"
                class="ub ub-btw trade-spotkline-side-picker"
                @click="showAction = true"
              >
                {{ actionText }}
                <img src="@/assets/images/market/down-arrows.svg"/>
              </div>
            </div>
            <div class="trade-spotkline-side-tableRow" v-for="(ask, aIdx) in deepsData.asks" :key="aIdx">
              <div class="trade-spotkline-side-sell">{{ ask.price }}</div>
              <div>{{ ask.quantity }}</div>
            </div>
          </div>
        </div>
        <div v-else-if="tab.label === 'marketTrades'" class="trade-spotkline-trades ub ub-btw">
          <div class="trade-spotkline-trade-item ub-f1">
            <div class="trade-spotkline-trade-item-title">
              {{ $t('trade.time') }}
            </div>
            <div class="trade-spotkline-trade-item-info" v-for="item in dealDatas" :key="item.agg_trade_id">
              {{ formatTime(item.time) }}
            </div>
          </div>
          <div class="trade-spotkline-trade-item ub-f1">
            <div class="trade-spotkline-trade-item-title">
              {{ $t('trade.price') }}
            </div>
            <div class="trade-spotkline-trade-item-info" :style="{'color': !item.maker ? `var(${upSty})` : `var(${downSty})`}" v-for="item in dealDatas" :key="item.agg_trade_id">
              {{ item.price }}
            </div>
          </div>
          <div class="trade-spotkline-trade-item ub-f1 quantity">
            <div class="trade-spotkline-trade-item-title">
              {{ $t('trade.quantity') }}
            </div>
            <div class="trade-spotkline-trade-item-info" v-for="item in dealDatas" :key="item.agg_trade_id">
              {{ item.quantity }}
            </div>
          </div>
        </div>
        <div v-else-if="tab.label === 'data'">$t(`trade.${tab.label}`)</div>
        <div v-else>$t(`trade.${tab.label}`)</div>
      </van-tab>
    </van-tabs>
    <van-action-sheet v-model="showAction">
      <button
        type="button"
        class="van-action-sheet__item"
        v-for="(action, index) in actions"
        :key="index"
        :class="{'borderB': (index+1) < actions.length}"
        @click="() => onSelect(action)"
      >
        <span
          class="van-action-sheet__name"
          :class="{
              active:
                (action.value === pickValue)
            }"
          >{{ action.text }}</span
        >
      </button>
    </van-action-sheet>
  </div>
</template>
<script>
import { TradeNews, TradeLaster } from '@/api/market.js'
export default {
  props: {
    deepsData: {
      type: Object,
      default () {
        return {
          bids: [],
          asks: []
        }
      }
    }
  },
  data () {
    return {
      showAction: false,
      tabActive: 0,
      upSty: '--up-color',
      downSty: '--down-color',
      tabs: [
        { label: 'orderBook', value: 1 },
        { label: 'marketTrades', value: 2 }
        // { label: 'data', value: 3 },
        // { label: 'projectDetails', value: 4 }
      ],
      pickValue: 3,
      actions: [
        { text: '0.0001', value: 0 },
        { text: '0.001', value: 1 },
        { text: '0.01', value: 2 },
        { text: '0.1', value: 3 }
      ],
      tradeBids: [],
      tradeAsks: [],
      dealDatas: [],
      dealNextId: null
    }
  },
  computed: {
    actionText () {
      return this.actions.find((fo) => fo.value === this.pickValue)
        ?.text
    },
    symbol () {
      return this.$route.params.symbol
    }
  },
  methods: {
    onSelect ({ value }) {
      this.showAction = false
      this.pickValue = value
    },
    async onPollingAction (isInit) {
      return await Promise.all([this.getTradeData(isInit), this.$parent.getDeepData()]).then((res) => {
        return res
      })
    },
    getTradeData (isInit) {
      const request = isInit ? TradeNews : TradeLaster
      return request({ symbol: this.symbol, next_agg_trade_id: this.dealNextId }).then(res => {
        if (res && res.next_agg_trade_id) {
          // this.dealDatas.length = 0
          const oldData = this.dealDatas
          this.dealDatas = [...res.agg_trade_list, ...oldData].sort((a, b) => a.time - b.time)
          this.dealNextId = res.next_agg_trade_id
          // res.agg_trade_list.forEach(item => {
          //   this.dealDatas[fn]({
          //     ...item
          //   })
          // })
        }
        return res
      })
    },
    formatTime (timestamp) {
      // 如果传入的时间戳无效，则返回空字符串
      if (isNaN(timestamp) || timestamp < 0 || timestamp > 8640000000000000) {
        return '--'
      }

      var date = new Date(timestamp)
      var hours = ('0' + date.getHours()).slice(-2)
      var minutes = ('0' + date.getMinutes()).slice(-2)
      var seconds = ('0' + date.getSeconds()).slice(-2)

      var dateString = hours + ':' + minutes + ':' + seconds
      return dateString
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/styles/mixins.less";
.trade-spotkline-actions {
  margin-top: 16px;
  background-color: var(--background-color-4);
  .trade-spotkline-side-wrapper {
    display: flex;
    justify-content: space-between;
    .trade-spotkline-side {
      width: 50%;
    }
    .trade-spotkline-side-marketTitle {
      margin: 10px 0 8px;
      height: 28px;
      color: var(--main-text-3);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
    .trade-spotkline-side-tableRow {
      display: flex;
      justify-content: space-between;
      line-height: 48px;
      position: relative;
      overflow: hidden;
      color: var(--main-text-2);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
    }
    .trade-spotkline-side-buy {
      color: var(--up-color);
    }
    .trade-spotkline-side-sell {
      color: var(--down-color);
    }
  }
  .trade-spotkline-side-picker {
    color: var(--main-text-2);
    background-color: var(--background-color-1);
    font-size: 24px;
    padding: 4px 16px;
    line-height: 28px;
    img {
      margin-left: 12px;
      width: 8px;
      height: 8px;
    }
  }
  .trade-spotkline-trades {
    .trade-spotkline-trade-item {
        color: var(--main-text-2);
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      .trade-spotkline-trade-item-title {
        margin-bottom: 16px;
        color: var(--main-text-3);
      }
      .trade-spotkline-trade-item-info {
        margin-bottom: 8px;
      }
    }
    .quantity {
      text-align: right;
    }
  }
  .active {
    color: var(--primary-color);
  }
  .van-tab {
    flex: none;
    margin-right: 48px;
    padding-bottom: 1.06667vw;
    font-size: 3.7vw;
    color: var(--main-text-1);
    &--active {
      .border-1px(var(--primary-color), solid, 12px);
      font-weight: unset;
    }
  }
  .van-tabs {
    &__wrap {
      padding: 0 4vw;
      height: 88px;
      border-bottom: 2px solid var(--divider-color);
    }
    &__line {
      background-color: transparent;
    }
    &__nav--line {
      padding: 0;
    }
    &__content {
      margin: 1.06667vw 0 1.6vw;
      padding: 0 4vw;
    }
  }
  .borderB {
    .border-1px(var(--divider-color), solid, 2px);
  }
}
</style>
