<template>
  <div class="spotkline-wrapper-spot">
    <div class="spotkline-wrapper-spot-quotaWrapper ub">
      <!-- 'ub-f1': quota.label !== 'divider',  -->
      <div
        class="spotkline-wrapper-spot-quotaWrapper-item"
        :class="{'space': quota.label === 'divider', 'active': currentIdxs.findIndex(cI => cI.label === quota.label) >= 0}"
        :style="{'text-align': qIdx > 5 ? 'center' : 'left'}"
        v-for="(quota, qIdx) in quotas"
        :key="qIdx"
      >
        <img src="@/assets/images/market/divider.svg" v-if="quota.label === 'divider'" />
        <span v-else @click="() => onSwitchIndex(quota, qIdx)">{{ quota.label }}</span>
      </div>
    </div>
    <div class="spotkline-wrapper-spot-indexWrapper ub" v-if="false">
      <div
        class="ub spotkline-wrapper-spot-indexWrapper-item ub-f1"
        v-for="(spot, sIdx) in percentList"
        :key="sIdx"
      >
        <p>{{ $t(`market.${spot.label}`) }}</p>
        <p :style="{ color: `var(${spot.cls})` }" class="percent">
          {{ spot.value }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
const TYPES = {
  M: 1,
  V: 2
}
export default {
  data () {
    return {
      isInit: true,
      currentIdxs: [{ label: 'VOL', type: TYPES.V }],
      quotas: [
        // { label: 'MA', type: TYPES.M },
        // { label: 'EMA', type: TYPES.M },
        // { label: 'BOLL', type: TYPES.M },
        // { label: 'SAR', type: TYPES.M },
        // { label: 'AVL', type: TYPES.M },
        // { label: 'divider' },
        { label: 'VOL', type: TYPES.V },
        { label: 'MACD', type: TYPES.V }
        // { label: 'RSI', type: TYPES.V },
        // { label: 'KDJ', type: TYPES.V }
      ],
      percentList: [
        { label: 'today', value: '-1.69%', cls: '--down-color' },
        { label: 'days7', value: '0.42%', cls: '--up-color' },
        { label: 'days30', value: '-4.42%', cls: '--down-color' },
        { label: 'days90', value: '-1.69%', cls: '--down-color' },
        { label: 'days180', value: '-1.69%', cls: '--down-color' },
        { label: 'year1', value: '-1.69%', cls: '--down-color' }
      ]
    }
  },
  created () {
    // 用于初始化 图表
    this.$emit('indexSpot', [{ label: 'VOL', type: TYPES.V }])
  },
  methods: {
    onSwitchIndex (quota, qIdx) {
      const isInside = this.currentIdxs.some(qI => qI.label === quota.label)
      if (isInside) {
        // 如果指标已存在，移除该指标
        this.currentIdxs = this.currentIdxs.filter(qI => qI.label !== quota.label)
      } else if (quota.type === TYPES.M) {
        // 如果是 M 类型的指标，只显示该指标，并移除其他 M 类型的指标
        this.currentIdxs = this.currentIdxs.filter(qI => qI.type !== TYPES.M)
        this.currentIdxs.push(quota)
      } else if (this.currentIdxs.some(qI => qI.label === qIdx.label)) {
        // 如果是 V 类型的指标且已经存在于当前显示的指标中，移除该指标（共存的情况）
        this.currentIdxs = this.currentIdxs.filter(qI => qI.label !== qIdx.label)
      } else {
        // 其他情况，将指标添加到当前显示的指标列表中
        this.currentIdxs.push(quota)
      }
      this.$emit('indexSpot', this.currentIdxs)
    }
  }
}
</script>
<style lang="less">
.spotkline-wrapper-spot {
  .spotkline-wrapper-spot-quotaWrapper, .spotkline-wrapper-spot-indexWrapper {
    align-items: flex-start;
    padding: 0 4vw;;
  }
  .spotkline-wrapper-spot-quotaWrapper {
    border-top: 1px solid var(--divider-color);
    border-bottom: 1px solid var(--divider-color);
    &-item {
      color: var(--main-text-3);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 200% */
      font-weight: 500;
      margin-right: 12px;
    }
    .space {
      width: 10px;
      padding-right: 8px;
    }
    .active {
      color: var(--primary-color);
    }
    img {
      vertical-align: middle;
    }
  }
  .spotkline-wrapper-spot-indexWrapper {
    padding: 8px 4vw 16px;
    margin-bottom: 12px;
    &-item {
      flex-direction: column;
      align-items: flex-start;
      color: var(--main-text-3);
      text-align: right;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 140% */
      text-transform: capitalize;
      .percent {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 140% */
      }
    }
  }
}
</style>
