import { dispose, init, utils } from 'klinecharts'
import { config } from './config'
import { KlineNews, KlineLaster } from '@/api/market.js'

export default {
  props: {
    deepsData: {
      type: Object,
      default () {
        return {
          bids: [],
          asks: []
        }
      }
    }
  },
  data () {
    return {
      active: 0,
      showTime: false,
      isTabs: true,
      current: 2,
      showK: true,
      showLine: false,
      activeTimeValue: null,
      intervals: [
        { label: 'm1', value: 1, text: '1m' },
        { label: 'm3', value: 2, text: '3m' },
        { label: 'm5', value: 3, text: '5m' },
        { label: 'm30', value: 4, text: '30m' },
        { label: 'h2', value: 5, text: '2h' },
        { label: 'h6', value: 6, text: '6h' },
        { label: 'h8', value: 7, text: '8h' },
        { label: 'h12', value: 8, text: '12h' },
        { label: 'd3', value: 9, text: '3d' },
        { label: 'w1', value: 10, text: 'w1' },
        { label: 'M1', value: 11, text: '1M' }
      ],
      tabs: [
        { label: 'line', value: 1, text: '1m' },
        { label: 'm15', value: 2, text: '15m' },
        { label: 'h1', value: 3, text: '1h' },
        { label: 'h4', value: 4, text: '4h' },
        { label: 'd1', value: 5, text: '1d' },
        { label: 'more', value: 6, icon: 'down-arrows' }
        // { label: 'depth', value: 7 }
      ],
      cacheKlineDatas: [],
      nextTime: null
    }
  },
  computed: {
    activeTimeLabel () {
      const labelObj = this.intervals.find(ft => ft.value === this.activeTimeValue)
      if (labelObj) {
        return this.$t(`trade.${labelObj.label}`)
      }
      return this.$t('trade.more')
    },
    symbol () {
      return this.$route.params.symbol || 'BTCUSDT'
    }
  },
  mounted () {
    this.chart = init('indicator-k-line', {
      styles: config,
      customApi: {
        formatDate (dateTimeFormat, timestamp) {
          return utils.formatDate(dateTimeFormat, timestamp, 'MM/DD HH:mm')
        }
      }
    })
    this.VOL = this.chart.createIndicator('VOL', false, {
      height: 42
    })
  },
  beforeDestroy () {
    dispose('indicator-k-line')
  },
  methods: {
    init () {
      return new Promise((resolve, reject) => {
        this.getKlineData().then(data => resolve(this.chart.applyNewData(data), reject))
      })
    },
    onSwitchTabs ({ value, label }) {
      this.chart.setStyles({
        candle: {
          type: label === 'line' ? 'area' : 'candle_solid'
        }
      })
      this.activeTimeValue = null
      this.current = value
      this.isTabs = true
      this.showK = label !== 'line' && label !== 'deep'
      this.showLine = !this.showK
      // 如果在工具栏切换
      const interval = this.tabs.find(iVal => iVal.value === this.current)
      this.cacheKlineDatas.length = 0
      this.onSwitchEcharts(interval.text)
    },
    onPickInterval (value, pValue) {
      this.activeTimeValue = value
      this.current = pValue
      // 用于判断最左边数据请求的类型
      this.isTabs = false
      // 如果在更多切换
      const interval = this.intervals.find(iVal => iVal.value === this.activeTimeValue)
      // 切换工具时间清空缓存数据
      this.cacheKlineDatas.length = 0
      this.onSwitchEcharts(interval.text)
    },
    getContainer () {
      return document.querySelector('.my-container')
    },
    onHideIndex (indexs) {
      if (!this.chart) return
      const Indicators = indexs.map(item => item)
      const hasVol = Indicators.find(f => f.label === 'VOL')
      const hasMACD = Indicators.find(f => f.label === 'MACD')
      if (hasVol && !this.VOL) {
        this.VOL = this.chart.createIndicator('VOL', false, {
          height: 52
        })
      } else if (!hasVol) {
        this.chart.removeIndicator(this.VOL, 'VOL')
        this.VOL = null
      }
      if (hasMACD && !this.MACD) {
        this.MACD = this.chart.createIndicator('MACD', false, {
          height: 59
        })
      } else if (!hasMACD) {
        this.chart.removeIndicator(this.MACD, 'MACD')
        this.MACD = null
      }
      if (Indicators.length === 0) {
        this.chart.removeIndicator()
      }
    },
    async onPollingKline () {
      const interval = this.isTabs ? this.tabs.find(iVal => iVal.value === this.current) : this.intervals.find(iVal => iVal.value === this.activeTimeValue)
      const data = await this.getKlineData(interval.text, 2, this.nextTime)
      if (data.length) {
        this.chart.updateData(data[0])
      }
      return this.cacheKlineDatas
    },
    async onSwitchEcharts (interval) {
      const data = await this.getKlineData(interval, 1)
      this.onInsetChart(data)
    },
    onInsetChart (data) {
      if (data && !data[0]) {
        return
      }
      this.chart.applyNewData(data)
    },
    // 获取k线数据,生成k线 type 1 新 2 上一个
    getKlineData (text = '15m', type = 1, startTime) {
      const getAPI = type === 1 ? KlineNews : KlineLaster
      const params = {
        interval: text,
        symbol: this.symbol,
        start_time: startTime
      }
      const data = []
      return getAPI(params).then(res => {
        if (res && res.length) {
          const len = res.length - 1
          this.nextTime = res[len].start_time
          res.forEach(item => {
            const { start_time: startTime, open, close, low, high, volume, quote_volume: turnover } = item
            data.push({ timestamp: startTime, close: +close, open: +open, volume: +volume, high: +high, low: +low, turnover: +turnover })
            this.cacheKlineDatas.push(
              { timestamp: startTime, close: +close, open: +open, volume: +volume, high: +high, low: +low, turnover: +turnover }
            )
          })
        }
        return data
      })
    }
  }
}
