<template>
    <Trade/>
</template>
<script>
import Trade from '@/components/trade'
export default {
  components: { Trade },
  computed: {
    title () {
      const [coin, payCoin] = this.$route.params.symbol?.split('.')
      return `${coin}/${payCoin}`
    }
  },
  methods: {
    onBack () {
      this.$router.push('/market')
    }
  }
}
</script>
